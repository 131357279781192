
import { Options, Vue } from 'vue-class-component';
import { UserClient, FriendRequestClient, ConversationRoomClient } from '@/services/Services';
import NewGroupModal from './newGroupModal.vue';
import store from '@/store';
import * as OM from '@/model';
import * as VM from '@/viewModel';

@Options({})
export default class NewChatModal extends Vue {

    users: OM.NameIdentifier[] = [];
    loading: boolean = true;
    search: string = "";

    vm: VM.CreateChatRoomVm = new VM.CreateChatRoomVm();

    mounted() {
        this.loading = false;
        this.doSearch();
    }
    
    doSearch() {
        if(!this.search.trim()) {
            this.loading = true;
            UserClient.getAllAsNameIdentifier()
            .then(x => {
                this.users = x;
            }).finally(() => this.loading = false)
        } else {
            this.loading = true;
            UserClient.search(this.search)
            .then(x => {
                this.users = x;
            }).finally(() => this.loading = false)
        }
    }

    open(item: OM.NameIdentifier) {
        if(store.state.user.identifier == item.identifier)
            return;

        store.state.selectedUser = item;
        
        Promise.all([
            FriendRequestClient.checkRequestSent(item.identifier),
            ConversationRoomClient.checkConversationExisting(item.identifier),
        ])
        .then(xs => {
            if(!xs[0] && !xs[1]){
                this.$router.push('/chats/newRequest/' + item.identifier);
            } else if(xs[0]){
                this.$router.push('/chats/request/' + xs[0]);
            } else if(xs[1]){
                this.$router.push('/chats/' + xs[1]);
            }
            this.$emit('close');
        })
    }

    createChatRoom() {
        this.$opModal.show(NewGroupModal, {})
    }

    debouncer: any = null;
    debouncia($event: any) {
        this.search = $event.target.value;
        clearTimeout(this.debouncer);
        this.debouncer = setTimeout(() => {
            this.doSearch();
        }, 300);
    }
}

