
import { Options, Vue } from 'vue-class-component';
import { BaseChatClient, FriendRequestClient } from '@/services/Services';
import { StorageServices } from '@/services/StorageServices';
import ConversationVm from '@/components/conversationVm.vue';
import NewChatModal from '../modals/newChatModal.vue';
import store from '@/store';
import * as OM from '@/model';

@Options({
    components: {
        ConversationVm
    }
})
export default class Chats extends Vue {

    loadingConversations: boolean = false;
    loadingRequests: boolean = false;
    chats: OM.ChatListVm[] = [];
    requests: OM.FriendRequestRoomVm[] = [];

    activeTab: string = "chats";

    created() {
        this.init();
    }

    init() {
        this.loadingRequests = true;
        Promise.all([
            FriendRequestClient.getRequestsSent(false),
            FriendRequestClient.getRequestsReceived(false)
        ])
        .then(xs => {
            this.requests = xs[0].concat(xs[1]);
            
        })
        .finally(() => this.loadingRequests = false);
        
        this.loadingConversations = true;
        BaseChatClient.getConversationsAndRooms(store.state.user.identifier, false)
        .then(x => {
            this.chats = x;
            StorageServices.setChatListVmList(x);
        })
        .finally(() => this.loadingConversations = false);
    }

    openSearchModal() {
        this.$router.push('/chats/social/search/');
    }

    openRequest(item: OM.FriendRequestRoomVm) {
        this.$router.push('/chats/request/' + item.identifier);
    }
    
    openChat(item: OM.ConversationRoomVm) {
        this.$router.push('/chats/' + item.identifier);
    }

    newChat() {
        this.$opModal.show(NewChatModal, {})
    }
}

