
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import Removable from '@/components/removable.vue';
import FileInput from '@/components/fileInput.vue';
import * as VM from '@/viewModel';
import { ChatRoomClient } from '@/services/Services';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({
    components: {
        Removable,
        FileInput
    }
})
export default class NewGroupModal2 extends Vue {

    @Prop() vm: VM.CreateChatRoomVm;

    placeholder: string = "";
    loading: boolean = false;

    created() {
        this.placeholder = LocalizationServices.getLocalizedValue("app_scriviunadescrizione", "Scrivi una descrizione");
    }

    get canSend() {
        if(!this.vm.name.trim() || this.vm.participants.length == 0 || !this.vm.image)
            return false;
        else
            return true;
    }

    preview: any = null;
    @Watch('vm.image')
    onImageChange(next, prev){
        this.makePreview();
    }
    makePreview(){
        if(this.vm.image){
            if(typeof this.vm.image == "string"){
                this.preview = this.vm.image;
            } else {
                var reader = new FileReader();
                reader.onload = (e: any) => {
                    this.preview = e.target.result;
                }
                reader.readAsDataURL(this.vm.image);
            }
        }
    }

    createRoom(){
        if(!this.canSend)
            return;
        ChatRoomClient.createChatRoom(this.vm)
        .then(x => {
            ChatRoomClient.updateChatroomImage(x, this.vm.image)
            .then( y => {
                this.$opModal.closeAll();
                this.$router.push('/chats/' + x);
            })
        })
    }
}

