
import { Options, Vue } from 'vue-class-component';
import { UserClient } from '@/services/Services';
import Removable from '@/components/removable.vue';
import NewGroupModal2 from './newGroupModal2.vue';
import * as VM from '@/viewModel';

@Options({
    components: {
        Removable
    }
})
export default class NewGroupModal extends Vue {

    users: VM.NameIdentifierSelected[] = [];
    loading: boolean = true;
    search: string = "";
    vm: VM.CreateChatRoomVm = new VM.CreateChatRoomVm();

    mounted() {
        this.loading = false;
        this.doSearch();
    }
    
    doSearch() {
        if(!this.search.trim()) {
            this.loading = true;
            UserClient.getAllAsNameIdentifier()
            .then(x => {
                this.users = x;
            }).finally(() => this.loading = false)
        } else {
            this.loading = true;
            UserClient.search(this.search)
            .then(x => {
                this.users = x;
            }).finally(() => this.loading = false)
        }
    }

    select(item: VM.NameIdentifierSelected){
        let ind = this.vm.participants.findIndex(x => x.identifier == item.identifier);
        if(ind > -1) {
            item.selected = false;
            this.vm.participants.splice(ind, 1);
        } else {
            item.selected = true;
            this.vm.participants.push(item);
        }
    }

    debouncer: any = null;
    debouncia($event: any){
        this.search = $event.target.value;
        clearTimeout(this.debouncer);
        this.debouncer = setTimeout(() => {
            this.doSearch();
        }, 300);
    }

    next(){
        this.$opModal.show(NewGroupModal2, {
            vm: this.vm
        });
    }
}

